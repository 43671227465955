@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.css';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif !important;
}
    .rtl {
      direction: rtl;
    }
    .loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes moveBackground {
  0% {
    background-position: 100% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}
*,
*:before,
*:after {
  @apply cursor-default;
}
[data-tooltip-id]:hover {
  @apply cursor-help;
}
a,
button {
  @apply cursor-pointer;
}
.react-tooltip {
  z-index: 5;
}
body {
  margin: 0;
font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./images/bg.jpeg);
  
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  direction: rtl;
  animation: moveBackground 120s linear infinite;
  /* Adjust the duration (20s) as needed */
}


.ql-editor {
  direction: rtl;
text-align: right;
}
.messageContent {
  max-width: 100%;
  line-height: 1.3;
  ol li {
      list-style: decimal;
    }
  ul li {
    list-style: disc;
  }
  h1{
    font-size: 24px;
  }
  h2{
    font-size: 20px;
  }
}
