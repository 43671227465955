@tailwind base;
@tailwind components;
@tailwind utilities;

// @layer components {
//   .container-width {
//     @apply w-full flex flex-col m-5;
//   }

//   .flex-row-center {
//     @apply flex flex-row justify-center items-center;
//   }

//   .flex-col-center {
//     @apply flex flex-col justify-center items-center;
//   }

//   .blur-bg {
//     @apply bg-black bg-opacity-20 backdrop-blur-sm border border-opacity-10 border-white p-5 rounded-xl;
//   }

//   .bg-opacity {
//     @apply bg-black bg-opacity-20;
//   }

//   .text-gradient {
//     @apply font-bold from-red-500 to-orange-500 bg-gradient-to-r bg-clip-text text-transparent underline;
//   }

//   .rounded-border {
//     @apply rounded-lg border border-white border-opacity-10;
//   }

//   .resource-box {
//     @apply w-full text-center mb-2 p-2 rounded-lg border border-white border-opacity-10;
//   }
// }

.slick-next {
    right: 25px;
}
.slick-prev {
    left: 25px;
    z-index: 1;
}
.slick-dots {
    bottom: 5px;
}