.even1 {
    background: #e8b28d;
}
.even2 {
    background: #c99571;
}
// .even1:hover {
//     background: #543c2c;
// }
// .even2:hover {
//     background: #543c2c;
// }

.desertcolor {
    color: #543c2c;
}
.desert2 {
    background: #744d26;
}
.desertletters {
    background: #543c2c;
}
.desertletters:hover {
    background: #744d26;
}
.evencolor {
    color: #797979;
}
.evencolorhover:hover {
    color: #797979;
}
.me {
    background: #a5887a;
}

.evenhover {
    background: #df915e;
}
.css-1mhcdve-MuiPieArc-root {
    stroke: #fff;
    stroke-width: 2px !important;
    stroke-linejoin: round;
}
