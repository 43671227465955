.ql-editor {
    direction: rtl;
    text-align: right;
    min-height: 200px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    left: 0;
    top: 50%;
    width: 18px;
    right: unset;
}
.ql-snow .ql-stroke {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #ffffff;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #f3f3f3;
    z-index: 2;
}
.ql-toolbar.ql-snow {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
}
.ql-container.ql-snow {
    border: 1px solid rgb(0 0 0 / 20%);
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before {color: white;}