
.login {

    h1 {
        font-size: 2rem;
    }
    p {
        font-size: 1.5rem;
    }
    a {
        color: white;
        text-decoration: none;
        display: flex;
        font-size: 1.3rem;
        
    }
}
i {
    display: inline-block;
        vertical-align: middle;
        width: 30px;
        text-align: center;
}
.active {
    background: #543c2c;
}
.borderdesert {
    border-color: #543c2c;
}
.active:hover {
    color: white;
    border: 0;

}
.game-container {
    width: 90%;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    /* Adjust timing function and duration */
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 20ms ease-in-out;
    /* Adjust timing function and duration */
}
.css-19qyu3f.ps-rtl {
    direction: rtl;
    border-right-width: none;
    border-left-width: 0px !important;
    border-right-style: none;
    border-left-style: solid;
}

.css-y2qln4 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    border-right: rgb(255 255 255 / 20%) solid 1px;
    border-left: rgb(255 255 255 / 20%) solid 1px;
}
